@import ../../../styles/helpers

.label
    +base1-s
    color: $n7
    +dark
        color: $n2

.tooltip
    position: relative
    top: -1px
    svg
        fill: $shades1
