@import ../../../styles/helpers

.field
    margin-bottom: 2px

.redLine
    +text-error
    margin-top: 2px
    margin-bottom: 2px
    margin-left: 12px
    color: red

.hidden 
    visibility: hidden
    
    