@import ../../styles/helpers

.logoContainer
    display: flex
    justify-content: flex-start
    margin-bottom: 24px

.logo
    display: inline-block
    width: 48px
    img
        width: 100%

.subLogo
    +text-logo-bold
    display: flex
    flex-direction: column
    justify-content: center
    margin-left: 8px
    +d
        display: none
    
    p
        &:first-child
            font-weight: 100
            margin-top: 0px
        padding: 0
        margin: 0
        margin-top: -8px
