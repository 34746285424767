@import ../../../../styles/helpers

.item
    +d
        position: relative
    +m
        position: static
    &.active
        .head
            border-color: $p1
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05)
            svg
                fill: $n7
                +dark
                    fill: $n1
        .body
            visibility: visible
            opacity: 1

.head
    display: flex
    min-height: 68px
    padding: 14px
    border-radius: 12px
    border: 2px solid $n3
    cursor: pointer
    transition: all .2s
    svg
        flex-shrink: 0
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    +dark
        border-color: $n6
    &:hover
        border-color: $p1
    
.category
    +caption2-m
    color: $n4

.body
    position: absolute
    z-index: 100
    top: 240px
    right: 0
    background: $n1
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    border-radius: 16px
    visibility: hidden
    opacity: 0
    transition: all .2s



