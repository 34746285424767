@import ../../styles/helpers

.pagination
  display: flex
  justify-content: center
  margin-top: 20px
  margin-bottom: 10px
  text-align: center
  .button
    .loader
      transform: scale(.8)
      margin: 0 18px 0 8px

.paginationActive
  font-weight: bold

.item
  align-items: center
  color: #808e9b
  cursor: pointer
  display: flex
  font-size: 14px
  height: 40px
  justify-content: center
  width: 40px
  a
    color: unset
    padding: 0 20px

.disabled-page
  color: #808e9b
.active
  border: solid 1px #808e9b
  border-radius: 40px
  color: #808e9b
.break-me
.next
  font-size: 20px
  height: 20px
  right: 0
.pagination
  align-items: center
  display: flex
  flex-direction: row
  height: 30px
  justify-content: center
  list-style-type: none
  position: relative
.pagination-page
  font-weight: 700
.previous
  font-size: 20px
  height: 20px
  left: 0