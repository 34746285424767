@import ../../styles/helpers

.label
    +base2
    color: $n5
    +dark
        color: $n3

.tooltip
    position: relative
    top: -1
    svg
        fill: $shades1

.head
    display: flex
    align-items: center
    margin-bottom: 12px
    .label
        margin-right: auto

.counter
    +caption1-m
    color: $shades1
    span
        color: $n7
        +dark
            color: $n1
