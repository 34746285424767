@import ../../styles/helpers


.container

.label

.checkbox
    width: 20px
    height: 20px



// .text
//     flex-grow: 1
//     padding-left: 16px
//     font-size: 15px
//     line-height: (24/14)
//     font-weight: 600
//     color: $n7
//     +dark
//         color: $n1
  