@import ../../../styles/helpers

.label
    margin-bottom: 14px
    +base2
    color: $n5
    +dark
        color: $n3

.tooltip
    position: relative
    top: -1px
    svg
        fill: $shades1

.tooltipError
    position: relative
    top: -1px
    svg
        fill: $p3

.date
    padding: 24px 12px
    +d
        width: 100%
        text-align: center

.foot
    padding-top: 24px
    border-top: 1px solid $n3
    text-align: right
    +dark
        border-color: $n6
    .button
        &:not(:last-child)
            margin-right: 8px

.title
    padding-right: 48px

.note
    margin-top: 24px
    padding-top: 24px
    border-top: 1px solid $n3
    font-weight: 500
    color: $n4
    +dark
        border-color: $n6

.list
    margin-top: 24px

.time
    width: 280px
    padding: 24px
    +d
        width: 100%

.top
    display: flex
    align-items: center
    margin-bottom: 24px
    padding-bottom: 23px
    border-bottom: 1px solid $n3
    +dark
        border-color: $n6

.subtitle
    margin-right: auto
    +title1-s

.close
    width: 36px
    height: 36px
    border-radius: 50%
    background: $n3
    font-size: 0
    svg
        fill: $n5
        transition: transform .2s
    +dark
        background: $n6
        svg
            fill: $n1
    &:hover
        svg
            transform: rotate(90deg)

.redLine
    +text-error
    margin-top: 2px
    margin-left: 12px
    color: red

.hidden 
    visibility: hidden
    




