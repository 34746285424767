@import ../../styles/helpers

.card
    position: relative
    background: $n1
    border-radius: 8px
    +m
        padding: 16px
    +dark
        background: $n7

.head
    display: flex
    align-items: center
    min-height: 40px
    border-bottom: 1px solid #efefef
    padding-bottom: 20px
    margin-bottom: 20px
    +m
        margin-bottom: 24px

.title
    margin-right: auto
    +m
        line-height: 40px
