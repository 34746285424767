.progress
  background-color: #dedede
  border-radius: 20px
  position: relative
  height: 20px
  width: 300px

.progress_done
  background: linear-gradient(to left, #34A853, #27d554)
  //box-shadow: 0 3px 3px -5px #34A853, 0 2px 5px #27d554
  border-radius: 20px
  color: #fff
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  width: 0
  font-size: 12px !important
  opacity: 1
  transition: 1s ease 0.3s
