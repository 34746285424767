[class^="status"]
    display: inline-block
    padding: 0 8px
    // background: rgba($dark3, .1)
    border-radius: 6px
    font-size: 12px
    line-height: 24px
    font-weight: 700
    // color: $dark2
.status-green-2
    background: #b6e3c9
    color: black

.status-green
    background: #EAFAE5
    color: $p2
    +dark-common
        background: rgba(#83BF6E, .15)
    &.border
        border: 1.5px solid #83c06d !important

.status-green-dark
    background: $s4
    color: $n7

.status-red
    background: #FFE7E4
    color: $p3
    +dark-common
        background: rgba($p3, .15)

.status-red-dark
    background: $s1
    color: $n7

.status-purple
    background: #CABDFF
    color: $p4

.status-purple-dark
    background: #CABDFF
    color: $n7

.status-blue
    background: $s3
    color: $p1

.status-blue-dark
    background: $s3
    color: $n7

.status-yellow
    background: $s5
    color: $n7

.status-yellow-dark
    background: $s1
    color: $n7

.status-dark
    background: $shades1
    color: $n5

.status-default
    background: $n3
    color: $n7

.status-green-border
    background-color: #f0f6ed
    border: 1.5px solid #c0dcb6 !important
    color: #83c06d !important

.status-red-border
    background-color: #fdeceb
    border: 1.5px solid #ffb6ad !important
    color: #fa6754 !important

.status-dark-border
    background-color: #e8e8e8
    border: 1.5px solid #b8bbc0 !important
    color: #626973 !important

.status-yellow-dark-border
    background-color: #ffbc99
    border: 1.5px solid #fea272 !important
    color: $n7 !important
