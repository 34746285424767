@import ../../styles/helpers

.filters
    position: relative
    &.active
        .body,
        .overlay
            visibility: visible
            opacity: 1

.body
    position: absolute
    top: -24px
    right: -24px
    z-index: 20
    width: 408px
    padding: 24px
    border-radius: 16px
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n1
    visibility: hidden
    opacity: 0
    transition: all .3s
    +m
        position: fixed
        top: 0
        right: 0
        left: 0
        bottom: 0
        width: auto
        overflow: auto
    +dark
        background: $n7

.top
    display: flex
    align-items: center
    margin-bottom: 24px

.title
    margin-right: auto

.close
    flex-shrink: 0
    width: 36px
    height: 36px
    border-radius: 50%
    background: $n3
    margin-left: 24px
    svg
        fill: $n7
        transition: transform .2s
    +dark
        background: $n6
        svg
            fill: $n1
    &:hover
        svg
            transform: rotate(90deg)

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 18
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: rgba($n6, .9)

.buttonSecondary
    border: 2px solid $n3
    padding: 8px 12px
    border-radius: 8px
    svg
        fill: #6F767E
        &:hover
            fill: black
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

